import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'gatsby-theme-husky/src/layout';
import Banner from 'gatsby-theme-husky/src/components/Banner';
import ShopCard from 'gatsby-theme-husky/src/components/ShopCard';

import { IBuyOnlineProps } from './model';

import 'gatsby-theme-husky/src/templates/BuyOnlinePage/BuyOnlinePageMain.scss';

const BuyOnlinePage: FC<IBuyOnlineProps> = ({
  data: {
    umbracoBuyOnline: { properties, url, defaultCompositions },
  },
}) => {
  const { seoMetaKeywords, seoMetaTitle, seoMetaDescription, seoCanonicalUrl } = properties;
  const { banner, title, shopCard, shopCardGroup } = properties;
  const isLazyLoading = true;

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      seo={{ seoMetaKeywords, seoMetaTitle, seoMetaDescription, seoCanonicalUrl }}
    >
      <Banner {...banner[0].properties} url={url} className="buy-online-page-banner" />
      {shopCardGroup?.map((group) => {
        const { shopGroupTitle, shopGroup } = group.properties;

        return (
          <div className="buy-online-page">
            <h2 className="buy-online-page__title">{shopGroupTitle}</h2>
            <div className="buy-online-page__content">
              {shopGroup
                ? shopGroup.map((card) => (
                    <ShopCard
                      key={`${card.properties.title}`}
                      card={card}
                      isLazyLoading={isLazyLoading}
                    />
                  ))
                : null}
            </div>
          </div>
        );
      })}
      {shopCard
        ? () => (
            <div className="buy-online-page">
              <h2 className="buy-online-page__title">{title}</h2>
              <div className="buy-online-page__content">
                {shopCard
                  ? shopCard.map((card) => (
                      <ShopCard
                        key={`${card.properties.title}`}
                        card={card}
                        isLazyLoading={isLazyLoading}
                      />
                    ))
                  : null}
              </div>
            </div>
          )
        : null}
    </Layout>
  );
};

export const query = graphql`
  query BuyOnlinePageCAM($url: String = "") {
    umbracoBuyOnline(url: { eq: $url }) {
      properties {
        seoMetaDescription
        seoMetaKeywords
        seoMetaTitle
        seoCanonicalUrl
        title
        banner {
          properties {
            backgroundColour
            title
            variant
          }
        }
        shopCard {
          localImage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 88) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          properties {
            title
            imageAltLabel
            button {
              properties {
                buttonLink {
                  url
                }
                buttonText
                ariaLabel
              }
            }
          }
        }
        shopCardGroup {
          properties {
            shopGroupTitle
            shopGroup {
              properties {
                title
                imageAltLabel
                button {
                  properties {
                    buttonText
                    ariaLabel
                    buttonLink {
                      url
                      name
                    }
                  }
                }
              }
              localImage {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 88) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      url
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoCanonicalUrl
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
    }
  }
`;

export default BuyOnlinePage;
